import React, {useEffect, useState} from 'react';
import {Page, Text} from "../Global";
import './Navigation.scss';
import {useNavigate} from "react-router-dom";


const pages = [
    {name: Text.Navigation.about, link: "About"},
    {name: Text.Navigation.crisisManagement, link: "CrisisManagement"},
    {name: Text.Navigation.struggles, link: "Struggles"},
    {name: Text.Navigation.articles, link: "Articles"},
    {name: Text.Navigation.contact, link: "Contact"},
    {name: Text.Navigation.collections, link: "/collections"},
] as Page[];



export default function Navigation() {
    // 0 -> initial, hamburger is closed | 1 -> hamburger is open | 2 -> hamburger is closing
    const [hamburgerState, setHamburgerState] = useState<0 | 1 | 2>(0);
    const nav = useNavigate();


    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth > 750)
                setHamburgerState(0);
        }

        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);


    useEffect(() => {
        const html = document.documentElement.style;
        if (hamburgerState === 1) {
            html.overflow = 'hidden';
        } else {
            html.overflow = 'auto';
        }
    }, [hamburgerState]);


    const NavItem = (props: { name: string, link: string, mobile?: boolean }) => {
        return <span onClick={() => navigate(props)} className="nav-item" key={props.name}>
            {props.name}
        </span>
    }


    const onHamburgerClick = () => {
        if (hamburgerState === 0)
            return setHamburgerState(1);

        if (hamburgerState === 1)
            return setHamburgerState(2);

        if (hamburgerState === 2)
            return setHamburgerState(1);
    }


    const onWhatsappClick = () => window.open("https://wa.me/9720547400740");


    const navigate = (props: { link: string, mobile?: boolean }) => {
        const {link, mobile} = props;

        if (link.startsWith("/"))
            return nav(link);

        const section = document.getElementById(`Section-${link}`) as HTMLDivElement;
        const header = document.getElementById(`Section-${link}-header`) as HTMLDivElement;

        if (!section) return;

        if (mobile) {
            setHamburgerState(2);
            return header.scrollIntoView({behavior: "smooth", block: "start"});
        }


        header.scrollIntoView({behavior: "smooth", block: "start"});
    }


    return <>
        <nav>
            <div className="right">
                <img src="/assets/logo.png" alt="logo"/>
                {pages.map((page, index) => <NavItem key={index} name={page.name} link={page.link}/>)}
                <span className="company-name-mobile nav-item">{Text.Header.companyName}</span>
            </div>

            <div className="left">
                {/*<div className="language-container round-button" onClick={onLanguageClick}>*/}
                {/*    { getLanguage() === "he" ?*/}
                {/*        <img src="https://cdn.britannica.com/53/1753-004-03582EDA/Flag-Israel.jpg" alt="עברית"/> :*/}
                {/*        <img src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg" alt="English"/>*/}
                {/*    }*/}
                {/*</div>*/}

                <button className="round-button" onClick={() => navigate({link: "Contact"})}>
                    צרו קשר
                </button>

                <button onClick={onWhatsappClick} className="round-button whatsapp">
                    <span>וואטסאפ</span>
                    <img src="/other/whatsapp.png" alt="whatsapp"/>
                </button>
            </div>

            <button className={`hamburger ${hamburgerState === 1 ? "hamburger-open" : ""}`} onClick={onHamburgerClick}>
                <div className="line"/>
                <div className="line"/>
                <div className="line"/>
            </button>
        </nav>

        <div className={`hamburger-menu ${hamburgerState === 1 && "hamburger-menu-open"} ${hamburgerState === 2 && "hamburger-menu-closing"}`}>
            {pages.map(page => <NavItem key={page.name} name={page.name} link={page.link} mobile/>)}

            <div className="hamburger-menu-footer">
                <button className="round-button" onClick={() => navigate({link: "Contact", mobile: true})}>
                    צרו קשר
                </button>

                <button onClick={onWhatsappClick} className="round-button whatsapp">
                    <span>דברו איתנו בוואטסאפ</span>
                    <img src="/other/whatsapp.png" alt="whatsapp"/>
                </button>
            </div>
        </div>
    </>
}


export const Footer = () => {
    const FooterItem = (props: { item: Page }) => {
        const {name, link} = props.item;

        const action = () => {
            if (link.startsWith("http"))
                return window.open(link);

            if (link.startsWith("/"))
                return window.location.href = link;


            const section = document.getElementById(`Section-${link}`) as HTMLDivElement;
            if (!section) return;

            section.scrollIntoView({behavior: "smooth", block: "start"});
        }

        return <>
            <div/>
            <span onClick={action} className="footer-item" key={name}>
            {name}
        </span>
        </>
    }

    return <footer>
        <label>{Text.Header.companyName}</label>

        {pages.map((item, index) => <FooterItem key={index} item={item}/>)}
    </footer>
}

