import {Client, Collection} from "../Collections/Types";


const AllergyDoctors: Client = {
    name: "האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית",
    url: "https://www.ima.org.il/MainSiteNew/EditUnion.aspx?UnionId=763",
    logo: "/other/allergy.png",
}



export const AllergyCommunication2024: Collection = {
    title: "האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית",
    client: AllergyDoctors,
    items: [
        {
            url: "https://www.bhol.co.il/n,ews/1679050",
            title: "כך תימנעו ממקרי מוות מיותרים, מהתקפי אלרגיה חמורים",
            image: "https://storage.bhol.co.il/articles/655489_tumb_800X480.jpg",
            publishDate: "21/11/2024",
            author: "אלי קליין"
        },
        {
            url: "https://apchi.co.il/?p=17172",
            title: "מחדל האפיפן: למה אין מענה למקרי חירום אלרגיים?",
            image: "https://apchi.co.il/wp-content/uploads/2024/11/WhatsApp-Image-2024-11-21-at-11.29.10.jpeg",
            publishDate: "21/11/2024",
            author: "שרהל'ה סנגאוי",
        },
        {
            url: "https://hamal.co.il/main/%D7%90%D7%99%D7%9A-%D7%9C%D7%9E%D7%A0%D7%95%D7%A2-%D7%91%D7%90%D7%A8%D7%A5-%D7%9E%D7%A7%D7%A8%D7%99-%D7%9E%D7%95%D7%95%D7%AA-%D7%9E%D7%99%D7%95%D7%AA%D7%A8%D7%99%D7%9D-%D7%9B%D7%AA%D7%95%D7%A6%D7%90%D7%94-%D7%9E%D7%94%D7%AA%D7%A7%D7%A4%D7%99-%D7%90%D7%9C%D7%A8%D7%92%D7%99%D7%94-%D7%97%D7%9E%D7%95%D7%A8%D7%99-155899",
            image: "https://image-resizer.walla.cloud/image/2024/11/3/images/1732170857281_picture_1414x1600.webp?width=424",
            title: "איך למנוע בארץ מקרי מוות כתוצאה מהתקפי אלרגיה חמורים?",
            publishDate: "21/11/2024",
            author: "מערכת חמ״ל"
        },
        {
            url: "https://www.emess.co.il/radio/1470627/",
            title: "איך למנוע בארץ מקרי מוות מיותרים, כתוצאה מהתקפי אלרגיה חמורים",
            image: "https://www.emess.co.il/resize/?width=800&height=450&url=/uploads/2024/08/WhatsApp-Image-2024-08-26-at-19.10.59.jpeg",
            publishDate: "21/11/2024",
            author: "גדי פוקס"
        },
        {
            url: "https://doctorsonly.co.il/2024/11/330973/",
            title: "בדיקת אלרגולוגית קבעה: שכיחות אלרגנית גבוהה לעץ הינבוט באילת",
            image: "https://cdn.doctorsonly.co.il/2024/11/shutterstock_1782461540-992x558.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "21/11/2024"
        },
        {
            url: "https://www.bhol.co.il/news/1678990",
            title: "גם זה קרה: העירייה שתלה עצים שהתבררו כאלרגניים לתושבים",
            image: "https://storage.bhol.co.il/media/lt/1342345/gallery/386117_tumb_800X480.jpg",
            publishDate: "20/11/2024",
            author: "קובי ברקת"
        },
        {
            url: "https://www.mako.co.il/health-magazine/Article-92b15b599604391027.htm",
            image: "https://img.mako.co.il/2024/11/19/yanbut_autoOrient_w.jpg",
            title: "האם נפתרה התעלומה? העץ שאחראי למרבית האלרגיות בדרום הארץ",
            publishDate: "20/11/2024",
            author: "אור הדר"
        },
        {
            url: "https://www.ynet.co.il/health/article/s1rpffcbkg?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=s1rpffcbkg&utm_content=Header",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/11/14/HJ800R8QMkx/HJ800R8QMkx_0_0_853_488_0_x-large.jpg",
            title: "קרדית, עובש ואלרגיה: כבר הקפאתם את הבובות בפריזר?",
            publishDate: "15/11/2024",
            author: "צור גואטה"
        },
        {
            url: "https://www.ynet.co.il/health/article/ryu2jrtkyl?utm_source=ynet.app.android&utm_medium=social&utm_campaign=general_share&utm_term=ryu2jrtkyl&utm_content=Header",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/10/13/rJXOZ8Kkye/rJXOZ8Kkye_0_40_1000_563_0_x-large.jpg",
            title: "המחלה של סגנית הנשיא קמלה האריס: מה ידוע על אורטיקריה? | מדריך",
            publishDate: "17/11/2024",
            author: "צור גואטה"
        },
        {
            url: "https://medpage.co.il/%d7%a2%d7%a7%d7%99%d7%a6%d7%94-%d7%91%d7%97%d7%92-%d7%94%d7%a1%d7%9b%d7%a0%d7%94-%d7%94%d7%9e%d7%aa%d7%95%d7%a7%d7%94-%d7%a9%d7%90%d7%95%d7%a8%d7%91%d7%aa-%d7%91%d7%a6%d7%9c%d7%97%d7%aa-%d7%94%d7%93/",
            image: "https://medpage.co.il/wp-content/uploads/2022/05/shutterstock_573536404.jpg",
            title: "עקיצה בחג: הסכנה המתוקה שאורבת בצלחת הדבש",
            author: "ד\"ר עידית לחובר רוט",
            publishDate: "19/11/2024"
        },
        {
            url: "https://www.ynet.co.il/health/article/byq24u46r?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=byq24u46r&utm_content=Header",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/07/26/Syz00mwRcn/Syz00mwRcn_0_0_1000_667_0_x-large.jpg",
            title: "עקיצת דבורה: מתי נגרמת תגובה אלרגית חריפה - ואיך נתגונן?",
            author: "ד\"ר עידית לחובר רוט",
            publishDate: "18/11/2024"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/846650",
            title: "האיגוד הישראלי לאלרגיה: יש ליישם המלצות על מזונות אלרגניים בביה\"ס",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-18-at-21.36.52-e1724007327161.jpeg?w=600&ssl=1",
            publishDate: "30/08/2024",
            author: "אריאל מונק"
        },
        {
            url: "https://www.ynet.co.il/health/article/r1sz3qs000?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=r1sz3qs000&utm_content=Header",
            title: "אלרגיה לחלב והמוות המיותר של אושר דרעי: מה השתנה מאז?",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/07/20/rkbllmtOR/rkbllmtOR_0_0_850_479_0_x-large.jpg",
            author: "פרופ' אלון הרשקו, פרופ' ארנון אליצור",
            publishDate: "21/07/2024"
        },
        {
            url: "https://doctorsonly.co.il/2024/07/322672/",
            title: "מה השתנה מאז המוות הטראגי של אושר דרעי ז\"ל מאלרגיה לחלב?",
            image: "https://cdn.doctorsonly.co.il/2024/07/shutterstock_1925456780-992x558.jpg",
            publishDate: "21/07/2024",
            author: "פרופ' אלון הרשקו ופרופ' ארנון אליצור"
        },
        {
            url: "https://www.makorrishon.co.il/health/778640/",
            title: "מומחים: תחליף חלב סמוך ללידה עלול לפתח אלרגיה אצל תינוקות",
            image: "https://www.makorrishon.co.il/wp-content/uploads/2023/01/shutterstock_665422312-750x375.jpg",
            publishDate: "18/08/2024",
            author: "אלינור פבריקר"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/843262",
            title: "מחקר מדעי קובע: תמ\"ל חלבי מיד לאחר הלידה מעלה את הסיכון לאלרגיה",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2019/01/ecc0a79e7873f80f4cf90c28b7565998.jpg?w=600&ssl=1",
            publishDate: "18/08/2024",
            author: "לי סעדון"
        },
        {
            url: "https://healthy.walla.co.il/item/3685282",
            title: "הנחיה חדשה לבתי חולים: אל תזינו תינוקות בתמ\"ל בימים הראשונים. זו הסיבה",
            image: "https://images.wcdn.co.il/f_auto,q_auto,w_1400/2/7/8/6/2786978-46.jpg",
            publishDate: "18/08/2024",
            author: "אביחי חיים"
        },
        {
            url: "https://tip.co.il/%d7%94%d7%96%d7%a0%d7%94-%d7%96%d7%9e%d7%a0%d7%99%d7%aa-%d7%91%d7%aa%d7%a8%d7%9b%d7%95%d7%91%d7%aa-%d7%9e%d7%96%d7%95%d7%9f-%d7%9c%d7%aa%d7%99%d7%a0%d7%95%d7%a7%d7%95%d7%aa-%d7%aa%d7%9e%d7%9c/",
            title: "הזנה זמנית בתרכובת מזון לתינוקות (תמ\"ל) חלבי, בימים הראשונים לחיים, עלולה להגביר את הסיכון לאלרגיה לחלב",
            image: "https://tip.co.il/wp-content/uploads/2024/08/%D7%99%D7%95%D7%A8-%D7%90%D7%99%D7%92%D7%95%D7%93-%D7%A8%D7%95%D7%A4%D7%90%D7%99-%D7%94%D7%90%D7%9C%D7%A8%D7%92%D7%99%D7%94-%D7%A4%D7%A8%D7%95%D7%A4-%D7%90%D7%A8%D7%A0%D7%95%D7%9F-%D7%90%D7%9C%D7%99%D7%9E%D7%95%D7%A8-%D7%A7%D7%99%D7%93%D7%98-%D7%99%D7%97%D7%A6.jpg",
            publishDate: "18/08/2024",
            author: "מערכת טיפ"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1125723",
            title: "סכנה בתזונה: הזנת תינוקות ופגים בפורמולות עם חלב עלולה לגרום לאלרגיה",
            author: "ד\"ר איתי גל",
            publishDate: "18/08/2024",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/927963"
        },
        {
            url: "https://www.mako.co.il/ninemonth-birth/Article-fd9ffffae456191026.htm",
            title: "תמ\"ל חלבי עלול להגביר את הסיכון לאלרגיה לחלב",
            author: "מאיה בר",
            image: "https://img.mako.co.il/2024/08/18/shutterstock_573616660_autoOrient_w.jpg",
            publishDate: "18/08/2024"
        },
        {
            url: "https://www.bhol.co.il/news/1671566",
            title: "המומחים מזהירים: הטעות הנפוצה של ההורים שעלולה לפתח אלרגיה אצל התינוקת",
            image: "https://storage.bhol.co.il/articles/652269_tumb_800X480.jpeg",
            author: "קובי ברקת",
            publishDate: "18/08/2024"
        },
        {
            url: "https://www.israelhayom.com/2024/08/18/baby-formula-post-birth-may-increase-allergy-risk/",
            title: "Baby formula post-birth may increase allergy risk",
            author: "Eleanor Favriker",
            image: "https://www.israelhayom.com/wp-content/uploads/2024/08/%D7%A9%D7%90%D7%98%D7%A8.webp",
            publishDate: "18/08/2024",
            english: true
        },
        {
            url: "https://103fm.maariv.co.il/programs/media.aspx?ZrqvnVq=JJGFEJ&c41t4nzVQ=FEE",
            title: "אמנון רגב התמקד בנושא אלרגיות באוכל ואירח את פרופ' ארנון אליצור, מנהל המכון לאלרגיה במרכז שמיר, ואת ד\"ר עדי עובדיה, רופאת אלרגיה",
            image: "https://103fm.maariv.co.il/download/highlight/SegmentImgNew_663216_50_21_.jpg",
            publishDate: "18/08/2024",
            author: "אמנון רגב",
            type: "audio"
        },
        {
            url: "https://doctorsonly.co.il/2024/08/324777/",
            title: "סיכון לאלרגיה לחלב בתינוקות המוזנים מוקדם מדי בתמ\"ל חלבי",
            image: "https://cdn.doctorsonly.co.il/2023/03/shutterstock_1938342232-992x558.jpg",
            publishDate: "18/08/2024",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://doctorsonly.co.il/2024/09/325795/",
            title: "אין להטיל איסור גורף על הכנסת מזון אלרגני למוסדות חינוך",
            image: "https://cdn.doctorsonly.co.il/2011/05/Little-girl-eating-breakfast_37004764.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "01/09/2024"
        },
        {
            url: "https://x.com/kann_news/status/1694273422426100075?s=46&t=piexRSFpj1f_MwZT0IcsNQ",
            title: "ראש שירותי בריאות הציבור, ד\"ר שרון אלרעי פרייס, מגיבה ב-#סדריום לפרסום בכאן חדשות",
            author: "כאן חדשות",
            publishDate: "23/08/2023",
            image: "/other/img13.png"
        },
        {
            url: "https://medpage.co.il/אין-לאסור-על-הכנסת-מזונות-אלרגניים-לבת/",
            title: "\"אין לאסור על הכנסת מזונות אלרגניים לבתי הספר ולגנים\"",
            image: "https://medpage.co.il/wp-content/uploads/2020/03/shutterstock_1177724563-1536x1024.jpg",
            publishDate: "23/08/2023",
            author: "מערכת medpage",
        },
        {
            url: "https://www.timesofisrael.com/allergy-doctors-say-its-time-to-bring-back-bamba-as-new-school-year-set-to-start/?utm_campaign=daily-edition-2023-08-27&utm_medium=email&utm_source=The+Daily+Edition",
            title: "Allergy doctors say it’s time to bring back Bamba as new school year set to start",
            image: "https://static.timesofisrael.com/www/uploads/2023/08/F200901NRF08-640x400.jpg",
            publishDate: "27/08/2023",
            author: "Renée Ghert-Zand",
            english: true
        },
        {
            url: "https://www.bhol.co.il/news/1600240",
            title: "כ-90% מהאנשים הנחשבים כאלרגים לפניצילין, אינם באמת אלרגיים",
            image: "https://storage.bhol.co.il/articles/607183_tumb_800X480.jpg",
            publishDate: "27/09/2023",
            author: "אלי קליין"
        },
        {
            url: "https://www.kore.co.il/viewArticle/140968",
            title: "כ-90% מהאנשים הנחשבים כאלרגים לפניצילין, אינם באמת אלרגיים",
            author: "אלי קליין",
            image: "https://www.kore.co.il/themes/HDB/images/share_logo.jpg",
            publishDate: "27/09/2023"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13618223?utm_source=ynet.app.android&utm_medium=social&utm_campaign=general_share&utm_term=yokra13618223&utm_content=Header",
            title: "שבועיים בלי מצנתר מוח בדרום: כך הופקרו תושבי הפריפריה | מעקב",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/10/05/B1W4RRnjxT/B1W4RRnjxT_0_0_1000_563_0_x-large.jpg",
            publishDate: "05/10/2023",
            author: "אדיר ינקו"
        },
        {
            url: "https://doctorsonly.co.il/2023/10/296346/",
            publishDate: "05/10/2023",
            title: "מחסור חמור במצנתרי מוח בפריפריה: \"חלון ההזדמנויות לטיפול נסגר",
            image: "https://cdn.doctorsonly.co.il/2017/02/חדר-אנגיו-992x558.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.algemeiner.com/2023/11/10/palestinian-islamic-jihad-says-willing-to-release-2-israeli-hostages/",
            title: "Palestinian Islamic Jihad Says Willing to Release 2 Israeli Hostages",
            publishDate: "10/11/2023",
            image: "https://www.algemeiner.com/wp-content/uploads/2022/08/2022-08-09T060443Z_1_LYNXMPEI7808B_RTROPTP_4_ISRAEL-PALESTINIANS-JIHAD-1.jpg",
            author: "Algemeiner Staff",
            english: true
        },
        {
            url: "https://tip.co.il/טעויות-באבחון-וטיפול-באלרגיה-שעלולות/",
            title: "טעויות באבחון וטיפול באלרגיה, שעלולות להיות טרגיות בימי מלחמה",
            image: "https://tip.co.il/wp-content/uploads/2023/11/פרופ-ארנון-אליצור-יור-איגוד-רופאי-האלרגיה-והאימונולוגיה-קלינית-בישראל-768x764.jpeg",
            publishDate: "21/11/2023",
            author: "מערכת טיפ"
        },
        {
            url: "https://doctorsonly.co.il/2023/11/299657/",
            title: "טעויות באבחון ובטיפול באלרגיה עלולות להיות טראגיות בימי מלחמה",
            image: "https://cdn.doctorsonly.co.il/2023/08/-2-e1692774665304-992x558.jpg",
            publishDate: "21/11/2023",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.jpost.com/health-and-wellness/article-774362",
            title: "Mistakes in allergy diagnosis during wartime injury treatment are tragic, but are avoidable",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/426251",
            publishDate: "21/11/2023",
            author: "Judy Siegel-Itzkovich",
            english: true
        },
        {
            url: "https://www.e-med.co.il/specialties/internal-medicine/2023/11/21/573769",
            title: "לטעויות באבחון וטיפול באלרגיה עלולות להיות השלכות טרגיות בזמן מלחמה (האיגוד לאלרגיה ואימונולוגיה קלינית)",
            image: "/other/img14.png",
            publishDate: "21/11/2023",
            author: "מערכת e-med"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1054998?utm_source=whatsapp",
            title: "באמצע המלחמה: חיילים בעלי אלרגיות הופתעו לגלות פגיעה בפרופיל",
            publishDate: "26/11/2023",
            author: "סתיו נמר",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/624959"
        },
        {
            url: "https://www.news08.net/נפתחות-מרפאות-אלרגיה-ואימונולוגיה-לט/",
            title: "נפתחות מרפאות אלרגיה ואימונולוגיה לטיפול במפונים בים המלח ובאילת",
            image: "/other/img15.jpg",
            author: "שרון מאירוביץ",
            publishDate: "26/11/2023"
        },
        {
            url: "https://doctorsonly.co.il/2023/11/300046/",
            title: "בצה\"ל החליטו להוריד פרופיל לחיילים עם אלרגיה למזון",
            image: "https://cdn.doctorsonly.co.il/2021/04/shutterstock_1268683939-992x558.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "27/11/2023"
        },
        {
            url: "https://www.maariv.co.il/news/israel/Article-1055312",
            title: "לאחר שסרטון מהשבי פורסם: יגיל יעקב חוזר הביתה",
            image: "/other/img10.png",
            publishDate: "27/11/2023",
            author: "מערכת מעריב"
        },
        {
            url: "https://www.jpost.com/health-and-wellness/article-775264",
            title: "IDF changes food allergy policy against expert medical advice",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/565664",
            publishDate: "29/11/2023",
            author: "Stav Namer",
            english: true
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1058877",
            title: "מחלות החורף כבר כאן: מהן הדרכים המומלצות להתגונן?",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/804566",
            publishDate: "11/12/2023",
            author: "סתיו נמר"
        },
        {
            url: "https://www.news08.net/מרפאת-האלרגיה-והאימונולוגיה-הנודדת-ב/",
            title: "מרפאת האלרגיה והאימונולוגיה הנודדת במלחמה מגיעה לאילת",
            image: "/other/img19.png",
            publishDate: "18/12/2023",
            author: "שרון מאירוביץ"
        },
        {
            url: "https://www.yomyom.net/article.asp?id=60701",
            title: "יש תשובה לסובלים מאלרגיה באילת",
            image: "https://www.yomyom.net//UploadImg/ArticlesNew/images/3151/31.jpg",
            publishDate: "22/12/2023",
            author: "מערכת יומיום"
        },
        {
            url: "https://www.news08.net/מרפאת-האלרגיה-והאימונולוגיה-מגיעה-לי/",
            title: "מרפאת האלרגיה והאימונולוגיה מגיעה לים המלח",
            publishDate: "27/12/2023",
            author: "שרון מאירוביץ",
            image: "/other/img20.png"
        },
        {
            url: "https://apchi.co.il/?p=15083",
            title: "ביום שלישי הקרוב: מרפאת האלרגיה הנודדת בדרך לים המלח",
            image: "https://apchi.co.il/wp-content/uploads/2023/12/IMG-20231227-WA0011.jpg",
            publishDate: "27/12/2023",
            author: "יוסף יצחק מיכאלשוילי"
        },
        {
            url: "https://ini.co.il/archives/3195",
            title: "אלרגים באזור ים המלח? יש לכם בשורה",
            image: "/other/img11.png",
            publishDate: "27/12/2023",
            author: "מערכת ini"
        },
        {
            url: "https://www.news08.net/מרפאות-אלרגיה-מתנדבות-להגיע-לים-המלח-ו/",
            title: "מרפאות אלרגיה מגיעות לים המלח ואילת",
            author: "שרון מאירוביץ",
            image: "/other/img17.png",
            publishDate: "17/01/2024"
        },
        {
            url: "https://www.0404.co.il/?p=952145",
            title: "מפונים מהבית וסובלים מאלרגיה? המרפאות שמופעלות בהתנדבות מלאה בשבילכם",
            image: "/other/img16.png",
            publishDate: "17/01/2024",
            author: "מערכת 0404"
        },
        {
            url: "https://www.eilatredc.co.il/articles/שירותי-הרפואה-בתחום-האלרגיה-באילת-ובי/",
            title: "שירותי הרפואה בתחום האלרגיה באילת ובים המלח מאוד דלים. מכבי נתנו חסות למרפאה בים המלח, כללית באילת.",
            image: "https://www.eilatredc.co.il/wp-content/uploads/2023/04/אילת-מהאוויר-שכונות-2048x1530.jpg",
            author: "רונית זילברשטיין",
            publishDate: "18/01/2024"
        },
        {
            url: "https://e-med.co.il/messages/2024/01/18/576962/",
            title: "מרפאות אלרגיה בים המלח ובאילת יופעלו בהתנדבות על ידי רופאות מומחות בתאריכים 25-26.1.24",
            image: "/other/allergy.png",
            publishDate: "18/01/2024",
            author: "מערכת e-med"
        },
        {
            url: "https://apchi.co.il/?p=15455#google_vignette",
            title: "בשורה לאלרגים: ה-FDA מאשר תרופה חדשה לטיפול באלרגיה",
            image: "https://apchi.co.il/wp-content/uploads/2024/02/medicine-3308108_640.jpg",
            author: "שרהל'ה סנגאוי",
            publishDate: "18/02/2024"
        },
        {
            url: "https://www.themarker.com/technation/2024-02-22/ty-article/.premium/0000018d-c0aa-dfd7-a9fd-f3be4fc90000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "ה-FDA אישר תרופה ראשונה להפחתת תגובות אלרגיות לסוגי מזון שונים",
            image: "https://img.haarets.co.il/bs/0000017f-f791-ddde-abff-fff5b7330000/af/ea/974c4412207d544d8c67a0623c6f/3396852982.jpg?precrop=1986,1154,x14,y181&width=1500&height=872&cmsprod",
            publishDate: "22/02/2024",
            author: "רותי לוי"
        },
        {
            url: "https://www.ynet.co.il/health/article/skufqzo6a?utm_source=ynet.app.android&utm_medium=social&utm_campaign=general_share&utm_term=skufqzo6a&utm_content=Header",
            title: "צעיר אלרגי לבוטנים מאושפז במצב קשה ברמב\"ם לאחר שאכל גלידה",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2021/01/13/B1bWTTu20P/B1bWTTu20P_0_0_1280_960_0_x-large.jpg",
            author: "אדיר ינקו",
            publishDate: "10/03/2024"
        },
        {
            url: "https://apchi.co.il/?p=15644",
            title: "שמחה הפכה לטרגדיה: צעיר אלרגי נאבק על חייו לאחר שאכל גלידה",
            image: "https://apchi.co.il/wp-content/uploads/2024/03/4e0aebd2-dca3-41a9-bcf2-509362bb5884.jpeg",
            author: "שרהל'ה סנגאוי",
            publishDate: "10/03/2024"
        },
        {
            url: "https://www.ynet.co.il/health/article/sjrse7ntp?utm_source=ynet.app.android&utm_medium=social&utm_campaign=general_share&utm_term=sjrse7ntp&utm_content=Header",
            title: "בבית החולים רמב\"ם בחיפה מאושפז בטיפול נמרץ צעיר בשנות ה-20 לחייו שלקה בהתקף חריף עקב אלרגיה לבוטנים.",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2019/04/21/9199921/9199921_0_0_1000_667_0_x-large.jpg",
            author: "ד\"ר יוסף פנסוף",
            publishDate: "11/03/2024"
        },
        {
            url: "https://www.haaretz.co.il/health/zevirali/2024-03-12/ty-article/.premium/0000018e-0425-d12c-a3bf-ffed9c000000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "פריחה, נזלת וקוצר נשימה - האם זו באמת אלרגיה למזון, או שיש סיבה אחרת?",
            image: "https://img.haarets.co.il/bs/0000018e-3294-d606-a5ff-7fb701530001/f0/3b/409509a545dfaddb66206bf9b504/27743.jpg?&width=1500&height=872&cmsprod",
            publishDate: "12/03/2024",
            author: "ארנון אליצור"
        },
        {
            url: "https://doctorsonly.co.il/2024/03/311917/",
            title: "רופא ישראלי בכיר מבקר את עמיתיו בחו\"ל במאמר בכתב עת בינלאומי",
            image: "https://cdn.doctorsonly.co.il/2024/03/196bd193-d4f7-4bb2-ae7a-c9ffba9444a4-e1710314983616-640x360.jpeg",
            publishDate: "13/03/2024",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/785716",
            title: "בעקבות המלחמה: פרופ' ישראלי מותח ביקורת חריפה על עולם הרפואה",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2024/03/WhatsApp-Image-2024-03-13-at-8.51.26-AM.jpeg?resize=614%2C1024&ssl=1",
            publishDate: "13/03/2024",
            author: "מערכת מבזק לייב"
        },
        {
            url: "https://www.jpost.com/health-and-wellness/article-777526",
            title: "How do you protect yourself from winter illnesses?",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/568036",
            publishDate: "17/12/2023",
            author: "Stav Namer",
            english: true
        },
        {
            url: "https://www.ynet.co.il/health/article/bjvs11sxga?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=bjvs11sxga&utm_content=Header",
            title: "עם השנים עולה השכיחות לתגובות אלרגיות למוצרי מזון שונים.",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2021/05/11/r16gS2vOu/r16gS2vOu_0_0_2000_1333_0_x-large.jpg",
            publishDate: "10/04/2024",
            author: "איתן גפן"
        },
        {
            url: "https://doctorsonly.co.il/2024/04/314014/",
            title: "על אף יעילותו, הטיפול באלרגיה לחלב מאתגר יותר במבוגרים",
            publishDate: "11/04/2024",
            image: "https://cdn.doctorsonly.co.il/2024/04/shutterstock_1027253149-992x558.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://healthy.walla.co.il/item/3658825",
            title: "9 מתוך 10 שחושבים שהם אלרגיים לפניצילין - טועים. וזה פועל לרעתם",
            image: "/other/img12.png",
            publishDate: "17/04/2024",
            author: "דורון קופרשטין"
        },
        {
            url: "https://103fm.maariv.co.il/programs/media.aspx?ZrqvnVq=JIEIKH&c41t4nzVQ=ELD",
            title: "אלרגים לפניצילין? כדאי לכם להקשיב",
            image: "https://103fm.maariv.co.il/download/programs/imgNew_180.jpg",
            publishDate: "19/05/2024",
            author: "ענת דוידוב",
            type: "audio",
        },
        {
            url: "https://apchi.co.il/?p=16026",
            title: "רופאים, שימו לב, קורס מקוון חדש יעשיר את הידע שלכם באלרגיה ואימונולוגיה",
            image: "https://apchi.co.il/wp-content/uploads/2024/05/2956a281-5add-41f8-bd73-a06964e5d6bc.jpeg",
            publishDate: "21/05/2024",
            author: "שרהל'ה סנגאוי"
        },
        {
            url: "https://doctorsonly.co.il/2024/05/316607/",
            title: "קורס לרופא הראשוני: מצבים שכיחים באלרגיה ואימונולוגיה",
            image: "https://cdn.doctorsonly.co.il/2018/12/shutterstock_662299669.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "21/05/2024"
        },
        {
            url: "https://www.haaretz.co.il/health/2024-07-09/ty-article/.premium/00000190-9245-dcf1-afdd-d265bf3a0000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "\"בקושי מצאתי מידע בעברית על המחלה של התינוק שלי\"",
            image: "https://img.haarets.co.il/bs/00000190-92aa-d1c5-a1d6-baeeabaa0000/23/a8/d64700764896aafdb16133207d48/704851-1.jpg?&width=1500&height=872&cmsprod",
            author: "נופר מרכוס מנדלבליט",
            publishDate: "09/07/2024"
        },
        {
            url: "https://doctorsonly.co.il/2024/07/321792/",
            title: "\"אם בקושי ידוע על התופעה, זה בטח מצב איום ונורא\"",
            image: "https://cdn.doctorsonly.co.il/2024/07/shutterstock_1720868686-992x558.jpg",
            publishDate: "10/07/2024",
            author: "ד\"ר נופר מרכוס מנדלבליט"
        },
        {
            url: "https://drkids.co.il/תזונת-תינוקות/אלרגיה-לחלב/",
            title: "אלרגיה לחלב -הזנה זמנית בתמ\"ל חלבי בימים הראשונים לחיים עלולה להגביר סיכון",
            image: "https://drkids.co.il/wp-content/uploads/2022/08/פרופסור-אילן-דלאל--1492x1536.jpeg",
            publishDate: "18/10/2024",
            author: "פרופסור אילן דלאל"
        },
        {
            url: "https://medpage.co.il/%D7%90%D7%A0%D7%A4%D7%99%D7%9C%D7%A7%D7%A1%D7%99%D7%A1-%D7%91%D7%99%D7%A9%D7%A8%D7%90%D7%9C-%D7%9E%D7%97%D7%A1%D7%95%D7%A8-%D7%91%D7%9E%D7%95%D7%9E%D7%97%D7%99%D7%9D-%D7%95%D7%91%D7%AA%D7%A9%D7%AA/",
            title: "אנפילקסיס בישראל: מחסור במומחים ובתשתיות מסכן חיים",
            image: "https://medpage.co.il/wp-content/uploads/2024/11/shutterstock_2178995849.jpg",
            publishDate: "24/11/2024",
            author: "מערכת MedPage"
        },
        {
            url: "https://www.yomyom.net/article.asp?id=64132",
            title: "מחקר הוכיח: האילתים אלרגיים יותר בגלל עץ הינבוט",
            image: "https://www.yomyom.net//UploadImg/ArticlesNew/images/3199/8.jpg",
            publishDate: "22/11/2024",
            author: "רותם נועם"
        },
        {
            url: "https://doctorsonly.co.il/2024/04/314419/",
            title: "9 מתוך 10 ישראלים שתויגו כאלרגיים לפניצילין אינם אלרגיים כלל",
            image: "https://cdn.doctorsonly.co.il/2018/10/shutterstock_105583055.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "11/04/2024"
        },
        {
            url: "https://www.ynet.co.il/health/article/hyb3mc6ja",
            title: "ארה\"ב: אושרה תרופה ביולוגית למניעת תגובה אלרגית קשה למזון",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/02/17/ByZvZoCToT/ByZvZoCToT_0_0_1000_667_0_x-large.jpg",
            author: "אדיר ינקו",
            publishDate: "17/02/2024"
        },
        {
            url: "https://www.maariv.co.il/news/health/article-1066900",
            title: "מרופאי שיניים ועד אורתופדים: הרופאים שמתנדבים בשעת המלחמה",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/886795",
            author: "אילנה שטוטלנד",
            publishDate: "11/01/2024"
        },
        {
            url: "https://apchi.co.il/?p=15002",
            title: "ביום ראשון הקרוב: מרפאת האלרגיה הנודדת מגיעה לאילת",
            image: "https://apchi.co.il/wp-content/uploads/2023/12/IMG-20231218-WA0042.jpg",
            author: "יוסף יצחק מיכאלשוילי",
            publishDate: "18/12/2023"
        },
        {
            url: "https://www.e-med.co.il/specialties/internal-medicine/2023/09/27/570615",
            title: "כ- 90% מהאנשים הנחשבים כאלרגים לפניצילין, אינם באמת אלרגיים (הודעת האיגוד הישראלי לאלרגיה ואימונולוגיה קלינית)",
            image: "/other/img22.png",
            author: "מערכת e-med",
            publishDate: "27/09/2023"
        },
        {
            url: "https://www.bhol.co.il/news/1596072",
            title: "אלרגים למזון? כך תעברו את החג בשלום",
            image: "https://storage.bhol.co.il/articles/246296_tumb_800X480.jpg",
            author: "קובי ברקת",
            publishDate: "14/09/2023"
        },
        {
            url: "https://www.kan.org.il/content/kan/kan-actual/p-11220/499336/",
            title: "תאגיד השידור כאן 11 - מהדורת חמש עם מאיה ראכלין",
            video: "/video/kan.mp4",
            publishDate: "23/08/2023",
            author: "מאיה ראכלין",
            type: "video",
            image: ""
        },
        {
            url: "https://www.kan.org.il/content/kan/kan-actual/p-11544/498159/",
            title: "תאגיד השידור כאן 11 - חדשות הערב עם מיכל רבינוביץ'",
            video: "/video/kan2.mp4",
            publishDate: "22/08/2023",
            author: "מיכל רבינוביץ'",
            type: "video",
            image: ""
        },
        {
            url: "https://omny.fm/shows/this-morning/23-8-2023#description",
            title: "רדיו - הבוקר הזה אריה גולן",
            author: "אריה גולן",
            publishDate: "23/08/2023",
            type: "audio",
            image: "https://www.omnycontent.com/d/programs/23f697a0-7e6a-4e96-a223-a82c00962b12/f7a18816-70f8-47ce-96c2-a98a00c5b150/image.jpg?size=Medium&t=1588863636",
            audio: "/audio/this_morning.mp3"
        }
    ]
}
