import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import {Item} from "./Types";
import React from "react";


const Audio = ({item}: { item: Item }) => {
    const {image, publishDate, author, audio} = item;


    return <div className="article-lower article-lower-audio">
        <div>
            <img src={image} alt="img"/>

            <div className="controls">
                <span>{publishDate}</span>
                <span>{author}</span>
            </div>
        </div>


        <ReactAudioPlayer src={audio} controls volume={0.7} className="audio-player"/>
    </div>
}


const Video = ({item}: { item: Item }) => {
    const {publishDate, author, video} = item;


    return <div className="article-lower article-lower-video">
        <div className="controls">
            <span>{publishDate}</span>
            <span>{author}</span>
        </div>

        <ReactPlayer url={video} controls volume={0.7} className="video-player"/>
    </div>
}


const ArticleLower = ({item}: { item: Item }) => {
    const {title, image, publishDate, author} = item;

    return <div className="article-lower">
        <div className="controls">
            <span>{publishDate}</span>
            <span>{author}</span>
        </div>

        <img src={image} alt={title}/>
    </div>
}


export const Article = ({item, i}: { item: Item, i: number }) => {
    const {title, image, publishDate, author, url} = item;

    const open = (url: string) => {
        if (articleType() === "video")
            return

        if (url.length > 0)
            window.open(url, '_blank');
    }

    const articleType = (): "audio" | "video" | "article" => {
        if (!item.type)
            return "article"

        if (item.type === "audio" && item.audio)
            return "audio"

        if (item.type === "video" && item.video)
            return "video"

        return "article"
    }


    const Lower = () => {
        const type = articleType();

        if (type === "audio")
            return <Audio item={item}/>

        if (type === "video")
            return <Video item={item}/>

        return <ArticleLower item={item}/>
    }


    return <div className="article" key={i} onClick={() => open(url)}>
        <div className="article-upper">
            <h3>{title}</h3>
            <span>{i + 1}</span>
        </div>

        <Lower/>
    </div>
}
