import {Client, Collection, CollectionList, CollectionListItem} from "../Collections/Types";
import {AllergyCommunication2024} from "./Allergy";



const LungsDoctors: Client = {
    name: "האיגוד הישראלי לרפואת ריאות",
    url: "https://lungs.doctorsonly.co.il/",
    logo: "/other/lungs-doctors.png",
};


const DreamDoctors: Client = {
    name: "עמותת רופאי חלום",
    url: "https://dreamdoctors.org.il/he/",
    logo: "/other/dream-doctors.png",
    favicon: "/other/dream-doctors-favicon.png",
};


const LittleSteps: Client = {
    name: "עמותת צעדים קטנים",
    url: "https://www.littlesteps.org.il/",
    logo: "/other/little-steps.png",
    favicon: "/other/little-steps-favicon.png",
};



const GrainImportersAssociation: Client = {
    name: "האיגוד הישראלי ליבואני התבואות",
    url: "",
    logo: "/other/grain-importers.png",
    favicon: "",
}


const MedicalDream: Collection = {
    title: "עמותת רופאי חלום",
    client: DreamDoctors,
    items: [
        {
            url: "https://allisrael.com/israel-s-dream-doctors-medical-clowns-bring-smiles-to-taiwanese-children-after-april-earthquake",
            title: "Israel's 'Dream Doctors' medical clowns bring smiles to Taiwanese children after April earthquake",
            publishDate: "05/06/2024",
            author: "All Israel News Staff",
            image: "https://res.cloudinary.com/hb0stl6qx/image/upload/w_900,c_scale,q_auto,f_auto,dpr_auto/v1717587892/AIN/Screenshot_2024-06-05_at_14.43.31.png",
            type: "article",
            english: true
        },
        {
            url: "https://www.jpost.com/health-and-wellness/mind-and-spirit/article-804167",
            title: "Medical clowns: Israel's Dream Doctors use therapeutic clowning for evacuees",
            publishDate: "01/06/2024",
            author: "PATRICIA GOLAN",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/600769",
            type: "article",
            english: true
        },
        {
            url: "https://omny.fm/shows/editcontent/8107dafc-1183-49b2-9f08-b17e009ca837",
            title: "הליצן הרפואי מניר עוז על הקושי מאחורי הצחוק",
            image: "https://www.omnycontent.com/d/clips/6dcbc33f-1fb6-49de-9ae2-ad8a00c01523/031bb697-72dd-44a4-92f3-ae52009bdf05/8107dafc-1183-49b2-9f08-b17e009ca837/image.jpg?t=1716889288&size=small",
            publishDate: "28/05/2024",
            author: "באדיבות המרואיין",
            audio: "/audio/2.mp3",
            type: "audio"
        },
        {
            url: "https://x.com/Now14Israel/status/1771643328544526744",
            title: "גיבורים בתחפושת: הליצנים הרפואיים שפועלים בבתי החולים כל שנה - מנסים להחזיר לפצועים את החיוך לפנים",
            publishDate: "23/03/2024",
            author: "ערוץ 14",
            image: "/other/img6.png",
            type: "video"
        },
        {
            url: "https://www.maariv.co.il/news/israel/Article-1085457",
            title: "\"בוכה בפנים\": ליצני בתי חולים משתפים באתגר המורכב שבזמן מלחמה\n",
            author: "דודי פטימר",
            publishDate: "21/03/2024",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/899683",
            type: "article"
        },
        {
            url: "https://ejewishphilanthropy.com/since-oct-7-medical-clowns-have-brought-humor-to-survivors-evacuees-a-new-study-says-its-helping/",
            title: "Since Oct. 7, medical clowns have brought humor to survivors, evacuees; a new study says it’s helping",
            image: "https://ejewishphilanthropy.nyc3.digitaloceanspaces.com/wp-content/uploads/2024/02/22072958/zaza-800x520.jpg",
            author: "Judith Sudilovsky",
            publishDate: "22/02/2024",
            type: "article",
            english: true
        },
        {
            url: "https://1075.fm/item/154331/",
            image: "/other/img23.png",
            title: "לא רק מצחיק: ליצנות רפואית תורמת לאיכות השינה ולקיצור זמן אשפוזם של המטופלים",
            publishDate: "25/02/2024",
            type: "article",
            author: "מערכת רדיו חיפה"
        },
        {
            url: "https://haipo.co.il/item/503334",
            title: "ליצנים רפואיים במרפאות כללית מחוז חיפה וגליל מערבי",
            publishDate: "20/02/2024",
            image: "/other/img7.png",
            type: "article",
            author: "כללית"
        },
        {
            url: "https://102fm.co.il/tzafir/?Summyid=154230",
            title: "נמרוד איזנברג, מנהל הפיתוח המקצועי של רופאי חלום, עמותת הליצנים הרפואיים ובעצמו ליצן רפואי עם שם הבמה- ''מקס'' על איך הומור עוזר לנו לרפא את הנפש?",
            publishDate: "02/02/2024",
            image: "https://www1.nobexpartners.com/ODItem.aspx?id=65bcf861911bd9e9217b7db9.46434.i.jpg",
            author: "נמרוד איזנברג",
            audio: "/audio/3.mp3",
            type: "audio",
        },
        {
            url: "https://www.be106.net/55/83691",
            title: "בית החולים הרצפלד בגדרה ערך הפנינג חגיגי יחד עם ליצנים רפואיים למען המטופלים והפגת הבדידות של בני הגיל השלישי במרכז הגריאטרי",
            publishDate: "21/01/2024",
            image: "https://newzim.co.il/merkaz/wp-content/uploads/sites/2/2024/01/%D7%9C%D7%99%D7%A6%D7%A0%D7%99%D7%9D-%D7%A8%D7%A4%D7%90%D7%95%D7%99%D7%99%D7%9D.jpeg",
            type: "article",
            author: "מערכת האתר",
            note: "הכתבה בעמוד 2"
        },
        {
            url: "https://www.ynetnews.com/health_science/article/rkf6v00oer",
            title: "Israeli medical clowns bring joy to Taiwanese children",
            author: "Itamar Eichner",
            publishDate: "01/06/2024",
            english: true,
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/format=auto/picserver5/crop_images/2024/05/29/B1RAIUE4A/B1RAIUE4A_0_0_2048_1536_0_x-large.jpg",
            type: "article",
        },
        {
            url: "https://www.mivzaklive.co.il/archives/814517",
            title: "נקודת אור דיפלומטית: ליצנות הומניטרית ישראלית בטייואן",
            publishDate: "29/05/2024",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2024/05/WhatsApp-Image-2024-05-29-at-12.00.45.jpeg?resize=1024%2C768&ssl=1",
            type: "article",
            author: "יובל אביב",
        },
        {
            url: "https://www.ynet.co.il/health/article/syho2une0?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=syho2une0&utm_content=Header",
            title: "ליצנים רפואיים מישראל באו לשמח ילדים בטייוואן אחרי רעידת אדמה",
            publishDate: "29/05/2024",
            author: "איתמר אייכנר",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/05/29/By4aL8NV0/By4aL8NV0_0_0_2048_1536_0_x-large.jpg",
            type: "article"
        },
        {
            url: "https://ntdtv.co.il/world/7926",
            title: "ליצנים רפואיים מישראל מגישים סיוע נפשי בטייוואן",
            publishDate: "01/06/2024",
            author: "NTD",
            image: "https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_1079/https://ntdtv.co.il/assets/uploads/2024/06/XXXXXXXXXXXXXXXXXX.jpg",
            type: "article"
        },
        {
            url: "https://newshaifakrayot.net/?p=325385",
            title: "מחקר חדש מהמרכז הרפואי כרמל קובע: ליצנים רפואיים משפרים את איכות השינה של ילדים המאושפזים בביה\"ח ותורמים לקיצור זמני אשפוזם",
            publishDate: "25/02/2024",
            author: "מערכת ניוז חיפה קריות",
            image: "https://newshaifakrayot.net/wp-content/uploads/2024/02/%D7%94%D7%9C%D7%99%D7%A6%D7%9F-%D7%90%D7%99%D7%AA%D7%99-%D7%A0%D7%97%D7%9E%D7%99%D7%90%D7%A1-%D7%A2%D7%9D-%D7%99%D7%9C%D7%93-%D7%91%D7%9E%D7%97%D7%9C%D7%A7%D7%AA-%D7%99%D7%9C%D7%93%D7%99%D7%9D-%D7%91%D7%9B%D7%A8%D7%9E%D7%9C-768x576.jpg",
            type: "article"
        },
        // {
        //     url: "https://www.rinunim.co.il/Article.php?CatID=&Page=&ArticleID=56882",
        //     title: "מחקר חדש מהמרכז הרפואי 'כרמל' מקבוצת 'כללית': ליצנים רפואיים משפרים את איכות השינה של ילדים המאושפזים בבית החולים.כתבה: רונית קיטאי",
        //     image: "https://www.rinunim.co.il/Pics/2024/20240220090240-s.jpg",
        //     publishDate: "20/02/2024",
        //     author: "רינונים",
        // },
        {
            url: "https://doctorsonly.co.il/2024/02/305803/",
            title: "מחקר ב\"כרמל\" הוכיח את תרומתם של ליצנים רפואיים לילדים באשפוז",
            publishDate: "20/02/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2024/02/WhatsApp-Image-2024-02-19-at-11.22.58-e1708409852501-992x558.jpeg",
        },
        {
            url: "https://apchi.co.il/?p=15448",
            title: "צחוק הוא התרופה: ליצנים רפואיים מקצרים אשפוזים ומשפרים שינה",
            image: "https://apchi.co.il/wp-content/uploads/2024/02/WhatsApp-Image-2024-02-18-at-08.48.08-683x1024.jpeg",
            publishDate: "18/02/2024",
            author: "שרהל'ה סנגאוי"
        },
        {
            url: "https://carmelist.co.il/item/85285",
            title: "ליצנים רפואיים משפיעים על איכות השינה של ילדים",
            publishDate: "19/02/2024",
            author: "מערכת כרמליסט",
            image: "https://carmelist.co.il/wp-content/uploads/2024/02/Screenshot-2024-02-19-085226-505x567.png"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1076979",
            title: "מחקר ישראלי חדש חושף: כך ליצנים רפואיים משפרים את מצבם של ילדים",
            publishDate: "18/02/2024",
            author: "ד\"ר איתי גל",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/894115"
        },
        {
            url: "https://apchi.co.il/?p=15337",
            title: "מחקר חדש מוכיח: כך משפיעים הליצנים הרפואיים על ההחלמה",
            publishDate: "29/01/2024",
            author: "יוסף יצחק מיכאלשוילי",
            image: "https://apchi.co.il/wp-content/uploads/2024/01/IMG-20240128-WA0022-1-768x576.jpg"
        },
        {
            url: "https://newzim.co.il/merkaz/?p=41413",
            title: "הפנינג של ליצנים רפואיים למשפחות המטופלים וצוות העובדים התקיים במרכז הרפואי גריאטרי שיקומי הרצפלד בגדרה",
            publishDate: "24/01/2024",
            author: "מערכת ניוזים",
            image: "https://newzim.co.il/merkaz/wp-content/uploads/sites/2/2024/01/%D7%9C%D7%99%D7%A6%D7%A0%D7%99%D7%9D-%D7%A8%D7%A4%D7%95%D7%90%D7%99%D7%99%D7%9D-%D7%91%D7%9E%D7%A8%D7%9B%D7%96-%D7%94%D7%92%D7%A8%D7%99%D7%90%D7%98%D7%A8%D7%99-%D7%92%D7%93%D7%A8%D7%94.jpeg"
        }
    ]
};


const LittleSteps2024part1: Collection = {
    title: "עמותת צעדים קטנים",
    client: LittleSteps,
    items: [
        {
            url: "https://tip.co.il/%D7%90%D7%A8%D7%94%D7%91-%D7%94%D7%95%D7%A1%D7%A8%D7%94-%D7%9E%D7%92%D7%91%D7%9C%D7%AA-%D7%94%D7%92%D7%99%D7%9C-%D7%A2%D7%9C-%D7%A9%D7%99%D7%9E%D7%95%D7%A9-%D7%91%D7%AA%D7%A8%D7%95%D7%A4%D7%94/",
            title: "ארה\"ב: הוסרה מגבלת הגיל על שימוש בתרופה אלוידיס לחולי ניוון שרירים מסוג דושן",
            publishDate: "21/06/2024",
            image: "https://tip.co.il/wp-content/uploads/2024/06/%D7%98%D7%9C%D7%99-%D7%A7%D7%A4%D7%9C%D7%9F-768x512.jpg",
            author: "חדשות טיפ",
            type: "article"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/798280",
            type: "article",
            publishDate: "15/04/2024",
            author: "מערכת חדשות מבזק לייב",
            title: "מחלה של בנים: מהי מחלת דושן – בקר וכמה חולים יש בישראל?",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2020/06/girl-holding-sidewalk-chalk.jpg?w=630&ssl=1",
        },
        {
            url: "https://vlu.co.il/article/1327",
            image: "https://vlu.co.il/articles_new/10336_tumb_750Xauto.jpg",
            title: "\"ישבתי בפסטיגל ובכיתי. היה לי תינוק בן שנה עם שעון חול מתקתק\"",
            author: "שרון רוטר",
            publishDate: "08/05/2024",
            type: "article"
        },
        {
            url: "https://shavvim.co.il/2024/06/13/%D7%9C%D7%9C%D7%9E%D7%95%D7%93-%D7%A2%D7%9C-%D7%A7%D7%91%D7%9C%D7%AA-%D7%94%D7%90%D7%97%D7%A8-%D7%93%D7%A8%D7%9A-%D7%9E%D7%A9%D7%97%D7%A7-%D7%A7%D7%9C%D7%A4%D7%99%D7%9D-%D7%9E%D7%99%D7%95/",
            title: "ללמוד על קבלת האחר – דרך משחק קלפים מיוחד",
            publishDate: "13/06/2024",
            image: "https://shavvim.co.il/wp-content/uploads/2024/06/%D7%9E%D7%A9%D7%97%D7%A7-%D7%96%D7%9B%D7%A8%D7%95%D7%9F-%D7%A6%D7%A2%D7%93%D7%99%D7%9F-%D7%A7%D7%98%D7%A0%D7%99%D7%9D-%D7%99%D7%9C%D7%93-%D7%91%D7%9B%D7%99%D7%A1%D7%90-%D7%92%D7%9C%D7%92%D7%9C%D7%99%D7%9D-%D7%9E%D7%A7%D7%91%D7%9C-%D7%9E%D7%AA%D7%A0%D7%94-%D7%9E%D7%99%D7%9C%D7%93%D7%94.jpg",
            type: "article",
            author: "גיא וקנין"
        },
        {
            url: "https://www.bhol.co.il/news/1665981",
            title: "הוסרה מגבלת הגיל על שימוש בתרופה לחולי ניוון שרירים",
            publishDate: "21/06/2024",
            author: "אלי קליין",
            image: "https://storage.bhol.co.il/media/lt/1562665/gallery/268208_tumb_800X480.jpg",
            type: "article"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/823949",
            title: "בשורה לחולי ניוון שרירים: הוסרה מגבלת הגיל לשימוש בתרופה אלוידיס",
            publishDate: "21/06/2024",
            author: "ארינאל מונק",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-21-at-11.08.22.jpeg?w=800&ssl=1",
            type: "article"
        },
        {
            url: "https://shavvim.co.il/2024/06/23/%D7%91%D7%A9%D7%95%D7%A8%D7%94-%D7%9C%D7%97%D7%95%D7%9C%D7%99-%D7%94%D7%93%D7%95%D7%A9%D7%9F-%D7%96%D7%95-%D7%94%D7%AA%D7%A8%D7%95%D7%A4%D7%94-%D7%A9%D7%A7%D7%99%D7%91%D7%9C%D7%94-%D7%90%D7%99%D7%A9/",
            title: "בשורה לחולי הדושן: זו התרופה שקיבלה אישור מה-FDA",
            publishDate: "23/06/2024",
            author: "מערכת שווים",
            image: "https://shavvim.co.il/wp-content/uploads/2024/06/vbQp_bxtQf2_yc-lrmpYjg.webp",
            type: "article"
        },
        {
            url: "https://doctorsonly.co.il/2024/06/319167/",
            title: "ה-FDA הסיר את מגבלת הגיל על התרופה אלוידיס לחולי דושן",
            author: "מערכת דוקטורס אונלי",
            publishDate: "24/06/2024",
            image: "https://cdn.doctorsonly.co.il/2024/06/shutterstock_219539785-992x558.jpg",
            type: "article"
        },
        {
            url: "https://www.e-med.co.il/specialties/dermatology/2024/06/04/582509",
            title: "כיצד לקדם מחקר במחלות נדירות? כנס של משרד הבריאות בנושא התקיים השבוע במתכונת מקוונת",
            publishDate: "04/06/2024",
            image: "/other/img2.png",
            type: "article",
            author: "מערכת e-Med"
        },
        {
            url: "https://www.ynet.co.il/activism/article/rkoklqfqr?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=rkoklqfqr&utm_content=Header",
            title: "\"המומחים לא הצליחו לאבחן את בני, אז הקמתי עמותה לקידום המודעות למחלה\"",
            publishDate: "27/05/2024",
            author: "אופיר האוזמן",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/04/25/HJs0FUDZ0/HJs0FUDZ0_0_483_1158_869_0_x-large.jpg",
            type: "article"
        }
    ]
};


const LungsDoctors2024part1: Collection = {
    title: "האיגוד הישראלי לרפואת ריאות",
    client: LungsDoctors,
    items: [
        {
            url: "https://doctorsonly.co.il/2023/11/299379/",
            title: "קריאה להרחיב את תכנית השיקום לחולי COPD בסל התרופות",
            publishDate: "26/11/2023",
            image: "https://cdn.doctorsonly.co.il/2022/11/-%D7%A4%D7%A8%D7%99%D7%93%D7%9C%D7%A0%D7%93%D7%A8-1-e1700121444985-992x558.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.e-med.co.il/specialties/family-medicine/2023/11/15/573490",
            title: "לרגל יום המודעות ל-COPD קורא יו\"ר האיגוד לרפואת ריאות להגדיל את התקציב לטיפול במחלה בסל הבריאות",
            publishDate: "15/11/2023",
            image: "/other/img5.png",
            author: "מערכת e-Med"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1052617",
            title: "מעשנים יותר מאז פרוץ המלחמה? אתם נמצאים בסיכון לחלות במחלה הזו",
            publishDate: "16/11/2023",
            author: "מעריב אונליין",
            image: "https://www.maariv.co.il/HttpHandlers/ShowImage.ashx?id=391709&w=690&h=470"
        },
        {
            url: "https://www.news08.net/%D7%99%D7%9E%D7%99-%D7%9E%D7%9C%D7%97%D7%9E%D7%94-%D7%9E%D7%97%D7%9C%D7%95%D7%AA-%D7%A8%D7%99%D7%90%D7%94-%D7%9B%D7%A8%D7%95%D7%A0%D7%99%D7%95%D7%AA-%D7%9E%D7%97%D7%9E%D7%99%D7%A8%D7%95%D7%AA-%D7%AA/",
            image: "/other/img4.png",
            title: "ימי מלחמה: מחלות ריאה כרוניות מחמירות תחת תנאי סטרס",
            author: "שרון מאירוביץ",
            publishDate: "04/12/2023",
        },
        {
            url: "https://www.0404.co.il/?p=939252",
            title: "תופעה מתרחבת: המחלות שמחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            author: "חדשות 0404",
            image: "/other/img9.png"
        },
        {
            url: "https://m.news1.co.il/Archive/001-D-478542-00.html?AfterBan=1",
            title: "תופעה מתרחבת: מחלות ריאה כרוניות מחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            image: "/other/img3.png",
            author: "מירב ארד"
        },
        {
            url: "https://www.bhol.co.il/news/1624794",
            title: "תופעה מתרחבת: מחלות ריאה כרוניות מחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            author: "ישראל לפקוביץ",
            image: "https://storage.bhol.co.il/articles/656579_tumb_800X480.jpg"
        },
        {
            url: "https://apchi.co.il/?p=14887",
            title: "לא כיף: איך מתמודדים עם מחלות ריאה כרוניות בימי מלחמה",
            author: "יוסף יצחק מיכאלשוילי",
            publishDate: "04/12/2023",
            image: "https://apchi.co.il/wp-content/uploads/2023/12/IMG-20231116-WA0006.jpg"
        },
        {
            url: "https://apchi.co.il/?p=14754",
            title: "ידעתם על זה? היום הוא יום המודעות בעולם ל- COPD מחלת ריאות חסימתית כרונית",
            image: "https://apchi.co.il/wp-content/uploads/2023/11/IMG-20231116-WA0006-768x748.jpg",
            author: "יוסף יצחק מיכאלשוילי",
            publishDate: "16/11/2023"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1057448",
            title: "המחלה המיסתורית מסין: באיזה חיידק מדובר והאם בעולם צריכים לחשוש?",
            publishDate: "05/12/2023",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/557840",
            author: "סתיו נמר"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/751108",
            title: "תופעה מתרחבת: מחלות ריאה כרוניות מחמירות בתקופת מלחמה",
            publishDate: "05/12/2023",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2023/12/pexels-mart-production-7088530.jpg?w=800&ssl=1",
            author: "איתמר רובינשטיין"
        },
        {
            url: "https://tip.co.il/%D7%94%D7%AA%D7%9E%D7%95%D7%93%D7%93%D7%95%D7%AA-%D7%A2%D7%9D-%D7%9E%D7%97%D7%9C%D7%95%D7%AA-%D7%A8%D7%99%D7%90%D7%94-%D7%9B%D7%A8%D7%95%D7%A0%D7%99%D7%95%D7%AA-%D7%91%D7%99%D7%9E%D7%99-%D7%94/",
            title: "התמודדות עם מחלות ריאה כרוניות בימי המלחמה",
            publishDate: "06/12/2023",
            image: "https://tip.co.il/wp-content/uploads/2023/12/%D7%A4%D7%A8%D7%95%D7%A4-%D7%A6%D7%91%D7%99-%D7%A4%D7%A8%D7%99%D7%93%D7%9C%D7%A0%D7%93%D7%A8-%D7%99%D7%95%D7%A8-%D7%90%D7%99%D7%92%D7%95%D7%93-%D7%A8%D7%95%D7%A4%D7%90%D7%99-%D7%94%D7%A8%D7%99%D7%90%D7%95%D7%AA-%D7%91%D7%99%D7%A9%D7%A8%D7%90%D7%9C-1-768x748.jpg",
            author: "מערכת טיפ"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13716936?utm_source=ynet.app.android&utm_medium=social&utm_campaign=whatsapp&utm_term=yokra13716936&utm_content=Header",
            title: "החולים חוזרים למסדרון: עלייה במחלות בדרכי הנשימה",
            publishDate: "14/12/2023",
            author: "אדיר ינקו",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/12/13/yk13716882/yk13716882_15_56_814_610_0_x-large.jpg"
        },
        {
            url: "https://www.haaretz.co.il/health/2024-02-14/ty-article/.premium/0000018d-a29f-d42f-adcd-ebff83310000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "למה האוצר הקפיא את מחיר הסיגריות האלקטרוניות?",
            publishDate: "14/02/2024",
            author: "אדיר רוקח",
            image: "https://img.haarets.co.il/bs/0000018d-a2b2-d400-a7cd-ffb7a2200001/a2/02/51e9e19e4660a03a0ff74bb25027/58228.jpg?&width=1500&height=872&cmsprod"
        },
        {
            url: "https://www.haaretz.co.il/health/2024-02-26/ty-article/.premium/0000018d-e548-d596-a9ef-e7fbf3130000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "\"כולם חולים עכשיו\" - האם מחלות החורף באמת הכו חזק יותר השנה?",
            author: "עידו אפרתי",
            publishDate: "26/02/2024",
            image: "https://img.haarets.co.il/bs/0000018d-e556-d47b-a1af-ff76d8130001/e6/52/7d7c50b345b492ecb2db33e4ddf8/689775.jpg?&width=1500&height=872&cmsprod"
        },
        {
            url: "https://doctorsonly.co.il/2024/04/314429/",
            title: "קופות החולים מתוגמלות על שיקום ריאות, אך לא מציעות אותו לחולים",
            publishDate: "17/04/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2024/04/shutterstock_794723350-992x558.jpg"
        },
        {
            url: "https://www.bhol.co.il/news/1661421",
            title: "מחלת האסתמה: \"היא עלולה להופיע בכל גיל\"",
            publishDate: "06/05/2024",
            image: "https://storage.bhol.co.il/articles/656579_tumb_800X480.jpg",
            author: "קובי ברקת"
        },
        {
            url: "https://apchi.co.il/?p=15941",
            title: "יום המודעות העולמי לאסתמה: לא אויב, אלא אתגר – בואו נתמודד יחד",
            publishDate: "06/05/2024",
            author: "שרהל'ה סנגאוי",
            image: "https://apchi.co.il/wp-content/uploads/2024/05/37882ecc-2b4c-49d1-949f-b958ceb4b6ee-768x768.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2024/06/319032/",
            title: "המשמעות של גישה פרו-אקטיבית בניהול מחלות נשימה כרוניות",
            publishDate: "23/06/2024",
            image: "https://cdn.doctorsonly.co.il/2021/10/shutterstock_1746786008.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.israelhayom.co.il/health/article/14598400",
            title: "חשופים לסכנה: זינוק בעישון בקרב בני נוער, כל ילד שלישי בישראל נחשף לעישון פסיבי",
            author: "מיטל יסעור בית-אור",
            publishDate: "12/09/2023",
            image: "https://www.israelhayom.co.il/wp-content/uploads/2022/04/04/1546205804912_b-960x640.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2023/09/295246/",
            title: "\"ישראל נכשלת במאבק בעישון\"",
            publishDate: "12/09/2023",
            image: "https://cdn.doctorsonly.co.il/2022/08/shutterstock_527589082-992x558.jpg",
            author: "מערכת דוקטורס אונלי"
        },
        {
            url: "https://www.hidabroot.org/article/1186011",
            title: "משרד הבריאות בדו\"ח מיוחד: עלייה מדאיגה במספר המעשנים בגילאי 11-12",
            publishDate: "12/09/2023",
            image: "https://storage.hidabroot.org/articles_new/342644_tumb_750Xauto.jpg",
            author: "שלומי דיאז"
        },
        {
            url: "https://www.mako.co.il/news-med12/lung_ambition/Article-c02943254ac3a81027.htm",
            title: "\"עבור ילדים ובני נוער סיגריה אלקטרונית זו עבודה בעיניים\"",
            publishDate: "06/09/2023",
            image: "https://img.mako.co.il/2019/09/25/shutterstock_233695804_x5.jpg",
            author: "אסנת עופר"
        },
        {
            url: "https://www.maariv.co.il/news/health/Article-1037913",
            title: "רופאים תוקפים את חזרת פינות העישון לנתב\"ג: \"יש לגנוז את הרעיון ויפה שעה אחת קודם\"",
            publishDate: "13/09/2023",
            image: "https://images.maariv.co.il/image/upload/f_auto,fl_lossy/c_fill,g_faces:center,h_470,w_690/488507",
            author: "סתיו נמר"
        },
        {
            url: "https://www.bhol.co.il/news/1595412",
            title: "גורמי רפואה במתקפה על הצעד בנתב\"ג: \"נזק בלתי הפיך\"",
            publishDate: "13/09/2023",
            author: "מאיר גלבוע",
            image: "https://storage.bhol.co.il/articles/246441_tumb_800X480.jpg"
        },
        {
            url: "https://www.ynet.co.il/health/article/yokra13618223?utm_source=ynet.app.android&utm_medium=social&utm_campaign=general_share&utm_term=yokra13618223&utm_content=Header",
            title: "שבועיים בלי מצנתר מוח בדרום: כך הופקרו תושבי הפריפריה | מעקב",
            publishDate: "05/10/2023",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2023/10/05/B1W4RRnjxT/B1W4RRnjxT_0_0_1000_563_0_x-large.jpg",
            author: "אדיר ינקו"
        },
        {
            url: "https://doctorsonly.co.il/2023/10/296346/",
            title: "מחסור חמור במצנתרי מוח בפריפריה: \"חלון ההזדמנויות לטיפול נסגר\"",
            image: "https://cdn.doctorsonly.co.il/2017/02/%D7%97%D7%93%D7%A8-%D7%90%D7%A0%D7%92%D7%99%D7%95-992x558.jpg",
            publishDate: "05/10/2023",
            author: "מערכת דוקטורס אונלי"
        },
    ]
};


const LungsDoctors2024part2: Collection = {
    title: "האיגוד הישראלי לרפואת ריאות",
    client: LungsDoctors,
    items: [
        {
            url: "https://www.jpost.com/israel-hamas-war/article-822203",
            title: "'Right to breathe freely': Israeli medical professionals think of hostages on World Lung Day\n",
            publishDate: "28/09/2024",
            author: "Jerusalem Post Staff",
            image: "https://images.jpost.com/image/upload/q_auto/c_fill,g_faces:center,h_537,w_822/620897",
            english: true
        },
        {
            //הרופאים הישראלים לעמיתיהם בעולם: "אסור לשכוח את החטופים, אין להם אוויר"
            // חברי האיגוד הישראלי לרפואת ריאות בהסתדרות הרפואית, שיגרו מכתב לעמיתיהם בעולם, בו הפצירו בהם "לחשוב על החטופים בכל יום - ולעזור להבטיח את זכותם הבסיסית לנשום"
            // שלו שינברג
            // כ"ג אלול התשפ"ד  26.09.24 | 09:07
            url: "https://www.bhol.co.il/news/1674980",
            title: "הרופאים הישראלים לעמיתיהם בעולם: \"אסור לשכוח את החטופים, אין להם אוויר\"",
            publishDate: "26/09/2024",
            author: "שלו שינברג",
            image: "https://storage.bhol.co.il/media/lt/1653832/gallery/710066_tumb_800X480.jpg"
        },
        {
            url: "https://www.ynet.co.il/health/article/hk80skwra",
            title: "רופאים מישראל בפנייה לעמיתיהם בעולם: \"אסור לשכוח את החטופים, אין להם אוויר\"",
            publishDate: "26/09/2024",
            author: "רון קריסי",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/09/21/rJylm53a0/rJylm53a0_0_146_3000_1688_0_x-large.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2024/09/327542/",
            title: "\"להבטיח את זכותם הבסיסית של החטופים לנשום בחופשיות\"",
            publishDate: "25/09/2024",
            author: "מערכת דוקטורס אונלי",
            image: "https://cdn.doctorsonly.co.il/2024/09/F240214MA015_pr-640x360.jpg"
        },
        {
            url: "https://www.mivzaklive.co.il/archives/853207",
            title: "חברי האיגוד הישראלי לרפואת ריאות מזכירים לעולם את החטופים בעזה",
            publishDate: "24/09/2024",
            author: "אריאל מונק",
            image: "https://i0.wp.com/www.mivzaklive.co.il/wp-content/uploads/2023/05/doctor-ge6cb519f3_1280.jpg?w=800&ssl=1"
        },
        {
            url: "https://doctorsonly.co.il/2024/08/324064/",
            title: "רופאים עם כוח-על",
            author: "טל דניאל-חבקוק",
            publishDate: "08/08/2024",
            image: "https://cdn.doctorsonly.co.il/2024/08/111-992x558.jpg"
        },
        {
            url: "https://medpage.co.il/%D7%9E%D7%97%D7%9C%D7%95%D7%AA-%D7%A8%D7%99%D7%90%D7%94-%D7%AA%D7%A2%D7%A1%D7%95%D7%A7%D7%AA%D7%99%D7%95%D7%AA-%D7%94%D7%90%D7%AA%D7%92%D7%A8-%D7%94%D7%A0%D7%A1%D7%AA%D7%A8-%D7%91%D7%A1%D7%91%D7%99/",
            title: "מחלות ריאה תעסוקתיות: האתגר הנסתר בסביבת העבודה",
            author: "ד\"ר עינת פיירמן-קליין",
            publishDate: "28/08/2024",
            image: "https://medpage.co.il/wp-content/uploads/2024/08/shutterstock_1870491376.jpg"
        },
        {
            url: "https://www.news08.net/%D7%96%D7%94%D7%95-%D7%99%D7%95%D7%9D-%D7%98%D7%A8%D7%90%D7%92%D7%99-%D7%9C%D7%97%D7%98%D7%95%D7%A4%D7%99%D7%9D-%D7%9C%D7%90-%D7%A0%D7%A9%D7%90%D7%A8-%D7%90%D7%95%D7%95%D7%99%D7%A8-%D7%91%D7%A8%D7%99/",
            title: "זהו יום טראגי, לחטופים לא נשאר אוויר בריאות ונגמר להם הזמן",
            author: "ליזה ללוצאשווילי",
            publishDate: "24/09/2024",
            image: "/other/img1.png"
        },
        {
            url: "https://www.bhol.co.il/news/1666787",
            title: "למעלה מ-400,000 חולים בארה\"ב בברונכיאקטזיס, בארץ - עשרות אלפי חולים",
            author: "אלי קליין",
            publishDate: "01/07/2024",
            image: "https://storage.bhol.co.il/media/lt/1652664/gallery/683667_tumb_800X480.jpg"
        },
        {
            url: "https://www.timesofisrael.com/on-world-lung-day-israeli-groups-highlight-plight-of-hostages-who-lack-even-air/",
            title: "On World Lung Day, Israeli groups highlight plight of hostages who ‘lack even air’",
            author: "Diana Bletter",
            publishDate: "26/09/2024",
            image: "https://static.timesofisrael.com/www/uploads/2024/01/WhatsApp_Image_2024-01-20_at_17.43.16-640x400.jpeg"
        },
        {
            url: "https://www.ynet.co.il/health/article/rkbp6nj9a",
            title: "האוויר שאנחנו נושמים בעבודה עלול לסכן. מה עושים?",
            author: "ד\"ר עינת פיירמן קליין",
            publishDate: "26/08/2024",
            image: "https://ynet-pic1.yit.co.il/cdn-cgi/image/f=auto,w=740,q=75/picserver5/crop_images/2024/07/29/HykRd4BFR/HykRd4BFR_0_0_1000_500_0_x-large.jpg"
        },
        {
            url: "https://aurora-israel.co.il/en/On-World-Lung-Day%2C-pulmonologists-pray-for-the-hostages-in-Gaza/",
            title: "On World Lung Day, pulmonologists pray for Gaza hostages",
            publishDate: "25/09/2024",
            author: "Aurora News",
            image: "/other/img8.png",
            english: true
        },
        {
            url: "https://x.com/kann_news/status/1780305876558942273",
            title: "ההסתדרות הרפואית שלחה למנכ\"לי קופות החולים מכתב חריף: אתם חוסכים על חולים שזקוקים לשיקום ריאות",
            author: "כאן חדשות",
            publishDate: "16/04/2024",
            image: "/other/img21.png"
        },
        {
            url: "https://medpage.co.il/%D7%9E%D7%94%D7%99-%D7%91%D7%A8%D7%95%D7%A0%D7%9B%D7%95%D7%A1%D7%A7%D7%95%D7%A4%D7%99%D7%94-%D7%95%D7%9E%D7%94-%D7%97%D7%A9%D7%99%D7%91%D7%95%D7%AA%D7%94-%D7%91%D7%90%D7%91%D7%97%D7%95%D7%9F-%D7%95/",
            title: "מהי ברונכוסקופיה ומהי חשיבותה באבחון וטיפול במערכת הנשימה?",
            author: "ד\"ר יבגני גרשמן",
            publishDate: "21/11/2024",
            image: "https://medpage.co.il/wp-content/uploads/2024/11/shutterstock_1807589032.jpg"
        },
        {
            url: "https://doctorsonly.co.il/2024/11/330894/",
            title: "נייר עמדה חדש: המלצות לשחרור לאחר אשפוז בשל התלקחות COPD",
            image: "https://cdn.doctorsonly.co.il/2020/11/shutterstock_470436206.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "20/11/2024"
        },
        {
            url: "https://doctorsonly.co.il/2023/11/299379/",
            title: "קריאה להרחיב את תכנית השיקום לחולי COPD בסל התרופות",
            image: "https://cdn.doctorsonly.co.il/2022/11/-%D7%A4%D7%A8%D7%99%D7%93%D7%9C%D7%A0%D7%93%D7%A8-1-e1700121444985-992x558.jpg",
            author: "מערכת דוקטורס אונלי",
            publishDate: "16/11/2024"
        },
        {
            url: "https://www.haaretz.co.il/health/2024-02-26/ty-article/.premium/0000018d-e548-d596-a9ef-e7fbf3130000?lts=1733321226066",
            title: "\"כולם חולים עכשיו\" - האם מחלות החורף באמת הכו חזק יותר השנה?",
            image: "https://img.haarets.co.il/bs/0000018d-e556-d47b-a1af-ff76d8130001/e6/52/7d7c50b345b492ecb2db33e4ddf8/689775.jpg?&width=1500&height=872&cmsprod",
            author: "עידו אפרתי",
            publishDate: "26/02/2024"
        },
        {
            url: "https://apchi.co.il/?p=17164",
            title: "COPD: סיבת המוות השלישית בעולם – ואיך מונעים אותה?",
            image: "https://apchi.co.il/wp-content/uploads/2024/11/%D7%AA%D7%9E%D7%95%D7%A0%D7%94-%D7%A9%D7%9C-WhatsApp%E2%80%8F-2024-11-20-%D7%91%D7%A9%D7%A2%D7%94-10.02.00_e701d4db.jpg",
            author: "שרהל'ה סנגאוי",
            publishDate: "20/11/2024"
        },
        {
            url: "",
            title: "איגוד רופאי הריאות של ההסתדרות הרפואית מאשים את ארבעת קופות החולים",
            image: "/other/img24.png",
            publishDate: "16/11/2024",
            audio: "/audio/1.mp3",
            author: "אריה גולן",
            type: "audio"
        },
    ]
};


const LungsDoctors2024merged: Collection = {
    title: "האיגוד הישראלי לרפואת ריאות",
    client: LungsDoctors,
    items: [...LungsDoctors2024part1.items, ...LungsDoctors2024part2.items]
};


const GrainImporters2024: Collection = {
    title: "איגוד יבואני התבואות",
    client: GrainImportersAssociation,
    items: [
        {
            url: "https://www.themarker.com/dynamo/2024-01-08/ty-article/0000018c-e88b-db55-a39e-ff9fe4370000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            image: "https://img.haarets.co.il/bs/0000018c-e92e-db55-a39e-ffbef26c0000/73/ca/19127b8c407aa766237c752da98b/62198.jpg?precrop=2324,1351,x0,y0&width=1500&height=872&cmsprod",
            title: "דו\"ח מבקר המדינה בנמל אשדוד, העבודה נעצרת כשהעובדים מגיעים לבונוס היומי",
            publishDate: "08/01/2024",
            author: "עידן בנימין",
        },
        {
            url: "https://www.themarker.com/dynamo/2024-02-12/ty-article/.premium/0000018d-9cd3-df62-a3fd-9edf629b0000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "מתקן פריקת תבואה בנמל אשדוד הושבת - ומסוע שהושקעו בו 250 מיליון שקל עומד שומם",
            image: "https://img.haarets.co.il/bs/0000018d-9cd8-db74-a1ef-dfdaed1b0000/f8/8e/019e5bad400a9a0679b6cdbb374a/74021.jpg?&width=1500&height=872&cmsprod",
            publishDate: "12/02/2024",
            author: "עידן בנימין",
        },

        {
            url: "https://www.themarker.com/dynamo/2023-09-12/ty-article/.premium/0000018a-854d-d16e-a78f-cf5d4d4f0000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "בתוך פחות מחצי שנה: בנמל אשדוד ריסקו מתקן לפריקת תבואות",
            image: "https://img.haarets.co.il/bs/0000018a-854d-d16e-a78f-cf5d4ca70000/47/ff/a4f7f35142388db3bac56791b596/53480611.JPG?&width=1500&height=872&cmsprod",
            publishDate: "12/09/2023",
            author: "עידן בנימין",
        },

        {
            url: "https://www.themarker.com/dynamo/2024-03-31/ty-article/.premium/0000018e-9433-d9a4-a7bf-dc7b53c90000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "למה נמל אשדוד צריך חשבון טיקטוק? כדי להתמודד עם הביקורת",
            image: "https://img.haarets.co.il/bs/0000018e-944d-d9a4-a7bf-dc5d5dae0000/02/e8/3a36060348f3bb56eb0b2a73f4fa/573705.jpg?&width=1500&height=872&cmsprod",
            publishDate: "31/03/2024",
            author: "עידן בנימין",
        },

        {
            url: "https://www.themarker.com/dynamo/cars/2024-04-17/ty-article/.premium/0000018e-e755-d0ca-a99f-f77fb1180000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "המתווה לתחרות בנמלים: משרדי האוצר והתחבורה נכנעים לעובדי נמל אשדוד",
            image: "https://img.haarets.co.il/bs/0000018e-e767-dba7-a3ee-ff7f9bb00000/d7/65/1ac435f54919848008b0c7166814/592343.jpg?&width=1500&height=1002&cmsprod",
            publishDate: "17/04/2024",
            author: "מירב ארלוזורוב"
        },

        {
            url: "https://www.themarker.com/dynamo/2024-07-30/ty-article/00000190-ff95-da63-a9d0-fffdc4d10000?utm_source=App_Share&utm_medium=Android_Native&utm_campaign=Share",
            title: "במשרד התחבורה נשענים על נמל אשדוד בחירום — ומתעלמים מפריקת המזון",
            image: "https://img.haarets.co.il/bs/00000190-ff95-da63-a9d0-fffdbe800000/06/ef/d0492ff34c7da23c9da474d5b402/57807628.JPG?&width=1500&height=1000&cmsprod",
            publishDate: "30/07/2024",
            author: "עידן בנימין"
        }
    ]
}




export const allCollections = (): CollectionList => {
    return [
        {id: "ls2024", collection: LittleSteps2024part1},
        {id: "l2023-24d", collection: LungsDoctors2024merged},
        {id: "ac2024", collection: AllergyCommunication2024},
        {id: "ga2024", collection: GrainImporters2024},
        {id: "md2024", collection: MedicalDream},
    ] as CollectionList;
}


export default function getCollection(path: string): CollectionListItem | null {
    for (const collectionItem of allCollections()) {
        const {id} = collectionItem;

        if (path === id)
            return collectionItem;
    }

    return null;
}


export const getCollectionDates = (collection: Collection): string => {
    const items = collection.items;
    // find the oldest and newest dates
    items.sort((a, b) => {
        const dateA = a.publishDate.split("/").reverse().join("");
        const dateB = b.publishDate.split("/").reverse().join("");

        return dateA.localeCompare(dateB);
    });

    const oldest = items[0].publishDate;
    const newest = items[items.length - 1].publishDate;

    // 06/09/2023 - 28/09/2024
    const [, oldestMonth, oldestYear] = oldest.split("/");
    const [, newestMonth, newestYear] = newest.split("/");

    const monthToText = (month: string): string => {
        const months = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"];
        return months[parseInt(month) - 1];
    }

    const oldestMonthText = monthToText(oldestMonth);
    const newestMonthText = monthToText(newestMonth);

    const oldestText = `${oldestMonthText} ${oldestYear}`;
    const newestText = `${newestMonthText} ${newestYear}`;
    return `${oldestText} - ${newestText}`;
}
